<template>
  <section id="quendrox-about" class="about readFriendlyLayout">
    <h2>Quendrox, your preferred partner</h2>
    <div id="about">
      <p>
        We are passionate, dedicated and positive-minded Telecom and IT
        professionals with a wide range of technical and management leaderships
        in in certification, testing and software development.
      </p>
      <p>
        We have managed and led multi-disciplined teams and projects in
        international environment with demonstrated success developing, driving
        and delivering efficiently and profitably. We are skilful international
        liaison, ensuring concerted projects success. Effective communicator,
        collaborator and business oriented project and test managers who
        consistently delivers clarity and results in a fast paced, dynamic
        environment. We are problem oriented and customer focused. We have
        excellent international social competence and understanding of the
        telecoms industry.
      </p>
      <p>
        We have experience of standardization and certification requirements
        regarding GCF, PTCRB, 3GPP, GSMA, OMA , NFC Forum, EMVCo, BT SIG, WiFi
        Alliance, CCC, FCC, R&TTE, CE, HDMI, DLNA, UPnP, Android etc. We have a
        comprehensive international network.
      </p>
    </div>

    <div id="in-our-world">
      <h3>In our world</h3>
      <div id="focus-areas" class="focusAreas">
        <div
          v-for="focusArea in focusAreas"
          v-bind:key="focusArea"
          class="focusArea"
        >
          <h4>{{ focusArea }}</h4>
          <img
            :src="getImagePath(`images/focus-areas/${focusArea}.png`)"
            :alt="focusArea"
            class="focusArea__image"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      focusAreas: [
        "Quests",
        "Expectations",
        "Solutions",
        "Home Automation",
        "Services",
        "Consulting"
      ]
    };
  },
  methods: {
    getImagePath: function(assetPath) {
      let formattedAssetPath = this.convertToAssetFormat(assetPath);
      return require(`@/assets/${formattedAssetPath}`);
    },
    convertToAssetFormat: function(menuOption) {
      return menuOption.toLowerCase().replace(" ", "-");
    }
  }
};
</script>

<style scoped lang="scss">
.focusAreas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  .focusArea {
    flex-basis: 50%;
    text-align: center;

    &__image {
      max-width: 90%;
      border-radius: 10px;
    }
  }
}

@media (min-width: 600px) {
  .focusAreas {
    .focusArea {
      flex-basis: 33%;
    }
  }
}
</style>
